export const ADT_MENUS = [];

export const MEDIA_WIDTH = {
    mobile:750,
    tablet:991
};
export const IMG_BASE_URL="https://m.xiwang.com/resource/"
export const IMG_ICON_PC="PWdEAFk1cah7qCjvjf-pm-1680857470069.png"
export const IMG_BANNER_PC="QHPIYpxeZjmzGDUELHklP-1702386952943.png"
export const IMG_ICON_MOBILE="8gxjomh_XLSxmDyFCQiwy-1681107348515.png"
export const IMG_BANNER_MOBILE="V2d46d292Drv37yDiZ-51-1702459286030.png"
export const HOME_IMAGES=[
    {
        key:1,
        title:"关于我们",
        url:"X7SOr-SspfWvIhhOwf34g-1702448271829.png"
    },
    {
        key:2,
        title:"关于我们",
        url:"fbNBk2zAY51Gek7N91cXk-1682254506994.png"
    },
    {
        key:3,
        title:"关于我们",
        url:"dzPq48HU9kteiex_zZCHv-1682254506995.png"
    },
    {
        key:4,
        title:"关于我们",
        url:"cUb-iZsOIiaCycRenU1M1-1682254506994.png"
    },
    {
        key:5,
        title:"关于我们",
        url:"y5elNMnzJ9imdasDIgNiT-1682254506995.png"
    },{
        key:8,
        title:"关于我们",
        url: "bHG5BV7ZHmnJjBEcmTL_f-1702625596029.png"
    },
    {
        key:9,
        title:"关于我们",
        url: "NT15Xz3FJ9nmIq2oAUPs3-1689057636233.png"
    },
    {
        key:10,
        title:"关于我们",
        url: "37nAOihkq-3VnE-wVfZdF-1689057636233.png"
    },
    {
        key:11,
        title:"关于我们",
        url: "gkhRwqglhw1NNUyi4WljG-1689057636233.png"
    },
    {
        key:12,
        title:"关于我们",
        url: "ugENBcsiU2mF6hy0Rg4M5-1689057636234.png"
    },
    {
        key:6,
        title:"为什么选择我们",
        url:"CFh4SFPBrrCtFFo5-Na28-1680857470068.png"
    },
    {
        key:7,
        title:"购买",
        url: "RsWHMHNhLckO4Ohmybig8-1681116924619.png"
    }
]
export const HOME_IMAGES_MOBILE=[
    {
        key:1,
        title:"关于我们",
        url:"cLXibfV8SPG3XUT3FSyBl-1702459286030.png"
    },
    {
        key:2,
        title:"关于我们",
        url:"I2RiTQMsxrbBJ4_iMdrJ_-1682254486631.png"
    },
    {
        key:3,
        title:"关于我们",
        url:"_PA0yA0KjdZzMEepao51v-1682254486632.png"
    },
    {
        key:4,
        title:"关于我们",
        url:"RqNs-uBKKwTI7y5bbBzKl-1682304527027.png"
    },
    {
        key:5,
        title:"关于我们",
        url:"WfMGwVjR22ATr-scu4YYh-1682254486632.png"
    },
    {
        key:8,
        title:"关于我们",
        url:"qdQ-IqgqThyckymu4SJSK-1689043473741.png"
    },
    {
        key:9,
        title:"关于我们",
        url:"HmNh6bUhkSk054BdRSPvV-1689043473742.png"
    },
    {
        key:6,
        title:"为什么选择我们",
        url:"S1JNNC9WuNO3OBonvaMIX-1681107348514.png"
    },
    {
        key:7,
        title:"购买",
        url:"QXhBGwr7XDQZJCvp_xFLa-1681118002424.png"
    }
]